import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import Search from "../../../../components/Header/Search/Search";
import CustomizeBalanceTable from "./CustomizeBalanceTable";
import AdjustLeaveBalanceSlidingPanel from "../../../../components/Leaves/SlidingPanels/AdjustLeaveBalanceSlidingPanel";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Pagination from "../../../../components/Pagination/Pagination";
import EmptyState from "../../../../components/EmptyState";
import Loader from "../../../../components/Loader";
import userRoleAuth from "../../../../utils/userRoleAuth/userRoleAuth";
import userRoles from "../../../../config/userRoles";
import { useSelector } from "react-redux";
import { toast} from "react-toastify";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const DEPARTMENT = "department";
const DESIGNATION = "designation";
const OFFICE = "office";
const FILTER = "filter";
const PAGENO = "pageNo";
const PAGESIZE = "pageSize";

const CustomizeBalance = () => {
  const user = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(user, userRoles.HR);
  const axiosPrivate = useAxiosPrivate();
  const [getEmployeeList, setGetEmployeeList] = useState({});
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [isAdjustBalanceSlidingPanelOpen, setAdjustBalanceSliginPanel] =
    useState(false);
  const [pageComponent, setPageComponent] = useState(<Loader/>);
  const [apiCall, makeApiCall] = useState(true);

  const [searchFilter, setFilter] = useState({
    pageNo: 1,
    pageSize: 10,
    filter: "",
    department: "",
    designation: "",
    office: "",
  });

  const isFiltered =
    searchFilter.filter ||
    searchFilter.department ||
    searchFilter.designation ||
    searchFilter.office;

  const fetchEmployeeLeaveBalanceList = async (url) => {
    if (!url) {
      return;
    }
    setGetEmployeeList({});
    setPageComponent(<Loader />);
    await axiosPrivate
      .get(url)
      .then((res) => {
        if (res?.data?.status) {
          setPageComponent(null);
          setGetEmployeeList(res?.data);
        } else {
          setPageComponent(<EmptyState />);
          setGetEmployeeList({});
        }
      })
      .catch((err) => {
        setGetEmployeeList({});
        setPageComponent(<EmptyState />);
        console.log(err);
      });
  };
  const [locationList, setLocationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);

  const fetchLocationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getLocationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setLocationList(
            data.result.map((i) => ({ label: i.location_name, value: i.id }))
          );
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchDepartmentList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDepartmentList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setDepartmentList(
            data.result.map((i) => ({ label: i.department_name, value: i.id }))
          );
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchDesignationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDesignationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setDesignationList(
            data.result.map((i) => ({
              label: i.designation_title,
              value: i.id,
            }))
          );
        }
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchLeaveTypes = async () => {
    await axiosPrivate
      .get("lms/leave/leaveTypesList?status=1&fullDetails=1")
      .then((res) => {
        if (res?.data?.status) {
          setLeaveTypes(res?.data?.data);
        }
      })
      .catch((res) => {
      //  console.log(res);
      });
  };

  useEffect(() => {
    const filterKeys = Object.keys(searchFilter);
    let baseUrl = `lms/leave/employeeLeaveBalanceList?sortdOrder=DESC`;
    filterKeys.forEach((filterKey) => {
      const value = searchFilter[filterKey];
      if (value) {
        baseUrl += `&${filterKey}=${value}`;
      }
    });
    const delayTimer = setTimeout(() => {
      fetchEmployeeLeaveBalanceList(baseUrl);
    }, 400);
    return () => {
      clearTimeout(delayTimer);
    };
  }, [searchFilter, apiCall]);

  useEffect(() => {
    fetchLeaveTypes();
    fetchDesignationList();
    fetchDepartmentList();
    fetchLocationList();
  }, []);

  const handleAdjustBalanceSlidingPanel = () => {
    setAdjustBalanceSliginPanel(true);
  };

  const handleSetFilter = (value, source) => {
    setFilter((prev) => ({
      ...prev,
      [source]: value,
    }));
  };
  

  const handleClearFilters = () => {
    setFilter((prev) => ({
      ...prev,
      filter: "",
      department: "",
      designation: "",
      office: "",
    }));
    selectBoxRef.current.designation.setValue("");
    selectBoxRef.current.department.setValue("");
    selectBoxRef.current.office.setValue("");
  };

  const selectBoxRef = useRef({
    designation: "",
    department: "",
    office: "",
  });

  // const getSearchValue = (e) => {
  //   handleSetFilter(e, FILTER)
  // }
  const formattingLeaveBalanceData = () => {
    const filterKeys = Object.keys(searchFilter);
    let baseUrl = `lms/leave/exportEmployeeLeaveBalance?`;
  
    filterKeys.forEach((filterKey) => {
      const value = searchFilter[filterKey];
      if (value) {
        baseUrl += `${filterKey}=${value}&`;
      }
    });
  
    const finalUrl = baseUrl.slice(0, -1);
    return finalUrl;
  };
  
  const handleDownloadForLeaveBalance = async () => {
    const url = formattingLeaveBalanceData();
  
    try {
      const response = await axiosPrivate.get(url, { responseType: "blob" });
  
      if (response?.status === 200) {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
  
        const contentDisposition = response.headers["content-disposition"];
        const filename =  "employee_leave_balance.csv";
  
        link.setAttribute("download", filename);
  
        document.body.appendChild(link);
        link.click();
        link.remove();
        toast.success("File downloaded");
      } 
       else {
        toast.error("Error while downloading the file!");
       // console.error("File is not available.");
      }
    } catch (error) {
      if(error?.message === "Request failed with status code 404") {
        toast.error("No data found for the selected employee!");
      }else{
        toast.error("Internal server error" || error?.message );
       // console.error("Error downloading the file:", error.message);
      }

      
    }
  };

  return (
    <>
      {isAdjustBalanceSlidingPanelOpen && (
        <AdjustLeaveBalanceSlidingPanel
          open={isAdjustBalanceSlidingPanelOpen}
          close={() => setAdjustBalanceSliginPanel(false)}
          employeeList={getEmployeeList?.employee || []}
          leaveTypes={leaveTypes}
          makeApiCall={makeApiCall}
          // getSearchValue={getSearchValue}
        />
      )}
      <div className="w-full overflow-x-auto">
        <div className="px-10 py-6">
          <div className="flex justify-between items-center pb-4">
            <div>
              <h2>{isHr ? "Adjust" : "Employee"} Leave Balance</h2>
              <p className="pb-4">
                {isHr ? "Customize and adjust all" : "View"} employee leave balance
              </p>
            </div>
            { isHr && <button className="btn" onClick={handleAdjustBalanceSlidingPanel}>
              <i className="ico-plus mr-2" />
              New Adjustment
            </button>}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex gap-3 items-center">
              <Select
                ref={(element) => (selectBoxRef.current.designation = element)}
                options={designationList}
                components={{ DropdownIndicator }}
                className="custom-select-container"
                placeholder="Designation"
                onChange={(e) => handleSetFilter(e.value, DESIGNATION)}
              />

              <Select
                ref={(element) => (selectBoxRef.current.department = element)}
                options={departmentList}
                components={{ DropdownIndicator }}
                className="custom-select-container"
                placeholder="Department"
                onChange={(e) => handleSetFilter(e.value, DEPARTMENT)}
              />
              <Select
                ref={(element) => (selectBoxRef.current.office = element)}
                options={locationList}
                components={{ DropdownIndicator }}
                className="custom-select-container"
                placeholder="Location"
                onChange={(e) => handleSetFilter(e.value, OFFICE)}
              />
              {isFiltered && (
                <span
                  className="text-blue-800 font-medium cursor-pointer"
                  onClick={handleClearFilters}
                >
                  Clear Filters
                </span>
              )}
            </div>
            <div>
                <Search onChange={(e) => handleSetFilter(e, FILTER)} />
              </div>
            <div className="flex">
              {isHr && (
  <btn onClick={handleDownloadForLeaveBalance} className="btn btn--border text-[14px]">
  <span>
    <i className="ico-download mr-1"></i>
  </span>
  Download
</btn>
              )}
              
            
            </div>
          </div>
        </div>
        {pageComponent ? (
          pageComponent
        ) : Array.isArray(getEmployeeList?.employee) &&
          getEmployeeList?.employee?.length ? (
          <>
            <CustomizeBalanceTable
              leaveTypes={leaveTypes}
              employeeList={getEmployeeList.employee}
              makeApiCall={makeApiCall}
            />
            <div className="mt-[65px]">
              <Pagination
                page={{
                  pageNo: searchFilter.pageNo,
                  setPageNo: (e) => handleSetFilter(e, PAGENO),
                  pageSize: searchFilter.pageSize,
                  setPageSize: (e) => handleSetFilter(e, PAGESIZE),
                  totalRecords: getEmployeeList?.totalRecords || 0,
                }}
              />
            </div>
          </>
        ) : (
          "Something went wrong, try again..."
        )}
      </div>
    </>
  );
};

export default CustomizeBalance;
