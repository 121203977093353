import React, { useState, useEffect, createContext } from "react";
import LeaveItem from "./LeaveItem";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "./MyLeaves.scss";
import ApplyLeaveModal from "../../../components/ApplyLeaveModal/ApplyLeaveModal";
import Pagination from "../../../components/Pagination/Pagination";
import Select from "react-select";
import Search from "../../../components/Header/Search/Search";
import EmptyState from "../../../components/EmptyState/EmptyState";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../utils/userRoleAuth/userRoleAuth";
import LeaveRequestList from "../../../components/Leaves/LeaveRequestList/LeaveRequestList";
import PageLoader from "../../../components/PageLoader";
import ApplyAdditionalLeaveModal from "../../../components/ApplyLeaveModal/ApplyAdditionalLeaveModal";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import LeaveDetailsModal from "../../../components/LeaveDetailsModal/LeaveDetailsModal";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import userRoles from "../../../config/userRoles";
import {
  useGetAdditionalLeaveListForEmployee,
  useGetLeaveBalances,
  useGetLeaveRequestList,
} from "../../../queries/leaveQueries";
const { RangePicker } = DatePicker;

const ADDITIONAL_LEAVE_VIEW = 2;
const LEAVE_VIEW = 1;

export const MyLeaveContext = createContext({});

const MyLeaves = () => {
  const { leaveApplicationIdFromParam } = useParams();
  const [searchParams] = useSearchParams();
  const additionalLeaveApplicationidFromParam = searchParams.get(
    "additional-leave-details"
  );

  const navigate = useNavigate();

  const authState = useSelector((state) => state.user);
  const { auth: user } = authState;
  const isHr = userRoleAuth(user, userRoles.HR);
  const isManager = userRoleAuth(user, userRoles.MANAGER);
  const isEmployee = userRoleAuth(user, userRoles.EMPLOYEE);
  const hrOrManager = isHr || isManager;

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [slidingPanel, setSlidingPanel] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [applicationDate, setApplicationDate] = useState(null);
  const [appliedDate, setAppliedDate] = useState(null);
  const [leaveType, setLeaveType] = useState(null);
  const [leaveStatus, setLeaveStatus] = useState(null);
  const [toggleState, setToggleState] = useState(
    additionalLeaveApplicationidFromParam ? ADDITIONAL_LEAVE_VIEW : LEAVE_VIEW
  );
  const [additionalLeaveModal, setApplyAdditionalLeaveModal] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);

  const isAdditionalLeaveTab = toggleState === ADDITIONAL_LEAVE_VIEW;
  const isLeaveTabView = toggleState === LEAVE_VIEW;

  // Api calls

  const { data: getLeaveBalanceData } = useGetLeaveBalances();
  const leaveBalances = Array.isArray(getLeaveBalanceData?.results)
    ? getLeaveBalanceData?.results
    : [];
  const searchHandle = (e) => {
    setSearchValue(e);
  };

  const handleDateRangeChange = (dates) => {
    if (dates && dates?.[0] && dates?.[1]) {
      setDateRange(dates);
    } else {
      setDateRange([null, null]);
    }
  };

  const [leaveListSearchParams, setLeaveListSearchParams] = useState({});

  const formatBaseUrlAndParams = () => {
    const params = {};
    const apiFilters = [
      {
        key: isLeaveTabView ? "applicationDate" : "application_date",
        value: applicationDate,
      },
      { key: "applied_for_date", value: appliedDate },
      {
        key: isLeaveTabView ? "fromDate" : "from_date",
        value: dateRange?.[0] ? dateRange[0].format("YYYY-MM-DD") : null,
      },
      {
        key: isLeaveTabView ? "toDate" : "to_date",
        value: dateRange?.[1] ? dateRange[1].format("YYYY-MM-DD") : null,
      },
      {
        key: isLeaveTabView ? "leaveType" : "leave_type_id",
        value: leaveType?.value,
      },
      { key: isLeaveTabView ? "status" : "", value: leaveStatus?.value },
      {
        key: isAdditionalLeaveTab ? "manager_approval_status_id" : "",
        value: leaveStatus?.value,
      },
      {
        key: isAdditionalLeaveTab ? "hr_approval_status_id" : "",
        value: leaveStatus?.value,
      },
      { key: "filter", value: searchValue },
    ];

    apiFilters.forEach((filter) => {
      if (filter?.key && filter?.value) {
        params[filter.key] = filter.value;
      }
    });

    const delayTimer = setTimeout(() => {
      setPageNo(1);
      setLeaveListSearchParams(params);
    }, 300);

    return () => {
      clearTimeout(delayTimer);
    };
  };

  useEffect(() => {
    formatBaseUrlAndParams();
  }, [
    searchValue,
    applicationDate,
    leaveType,
    leaveStatus,
    dateRange,
    appliedDate,
    toggleState,
  ]);

  const isFilterApplied =
    searchValue ||
    applicationDate ||
    leaveType ||
    appliedDate ||
    leaveStatus ||
    dateRange?.[0] ||
    dateRange?.[1];

  const clearAllFilters = () => {
    setSearchValue(null);
    setApplicationDate(null);
    setLeaveType(null);
    setLeaveStatus(null);
    setDateRange([null, null]);
    setAppliedDate(null);

    const fromDateInput = document.getElementById("fromDate");
    const toDateInput = document.getElementById("toDate");

    if (fromDateInput) {
      fromDateInput.value = "";
    }
    if (toDateInput) {
      toDateInput.value = "";
    }
  };

  const {
    data: getLeaveRequestListData,
    isLoading: isGetLeaveRequestListDataLoading,
    refetch: refetchGetLeaveRequestList,
  } = useGetLeaveRequestList(
    { ...leaveListSearchParams, pageNo, pageSize },
    isLeaveTabView
  );

  const {
    data: getAdditionalLeaveListForEmployeeData,
    isLoading: isGetAdditionalLeaveListForEmployeeLoading,
    refetch: refetchGetAdditionalLeaveListForEmployee,
  } = useGetAdditionalLeaveListForEmployee(
    {
      ...leaveListSearchParams,
      page_number: pageNo,
      page_limit: pageSize,
      sort_order: "DESC",
    },
    isAdditionalLeaveTab
  );

  const handleRefetchData = () => {
    if (isLeaveTabView) {
      refetchGetLeaveRequestList();
    } else if (isAdditionalLeaveTab) {
      refetchGetAdditionalLeaveListForEmployee();
    }
  };

  let leaveData = [];

  let totalRecords = 0;

  const isLoading =
    isGetLeaveRequestListDataLoading ||
    isGetAdditionalLeaveListForEmployeeLoading;

  if (
    isLeaveTabView &&
    Array.isArray(getLeaveRequestListData?.leaveRequestList)
  ) {
    leaveData = getLeaveRequestListData.leaveRequestList;
    totalRecords = getLeaveRequestListData?.totalRecords || 0;
  } else if (
    isAdditionalLeaveTab &&
    Array.isArray(getAdditionalLeaveListForEmployeeData?.additional_leave_list)
  ) {
    leaveData = getAdditionalLeaveListForEmployeeData.additional_leave_list;
    totalRecords = getAdditionalLeaveListForEmployeeData?.total || 0;
  }

  //state for applyleave modal
  const [applyLeaveSlidingPanel, setApplyLeaveSlidingPanel] = useState(false);

  // function for apply leaveDetailsSlidingPanel
  const applyleave = () => {
    if (isLeaveTabView) setApplyLeaveSlidingPanel(true);
    else setApplyAdditionalLeaveModal(true);
  };

  const toggleTab = (e) => {
    clearAllFilters();
    setToggleState(e);
  };

  useEffect(() => {
    if (leaveApplicationIdFromParam || additionalLeaveApplicationidFromParam) {
      setSlidingPanel(true);
    }
  }, [leaveApplicationIdFromParam, additionalLeaveApplicationidFromParam]);

  const handleCloseSlidingPanel = () => {
    navigate("/leave/my-leave/", { replace: true });
    setSlidingPanel(false);
  };

  const handleSetPageSize = (e) => {
    setPageNo(1);
    setPageSize(e);
  };

  return (
    <MyLeaveContext.Provider
      value={{
        isAdditionalLeaveTab,
        isLeaveTabView,
      }}
    >
      <LeaveDetailsModal
        open={slidingPanel}
        close={handleCloseSlidingPanel}
        leaveApplicationId={
          isAdditionalLeaveTab
            ? additionalLeaveApplicationidFromParam
            : isLeaveTabView
            ? leaveApplicationIdFromParam
            : ""
        }
      />
      {applyLeaveSlidingPanel ? (
        <ApplyLeaveModal
          open={applyLeaveSlidingPanel}
          close={() => setApplyLeaveSlidingPanel(false)}
          leaveTypes={leaveBalances}
        />
      ) : null}

      {additionalLeaveModal && (
        <ApplyAdditionalLeaveModal
          open={additionalLeaveModal}
          close={() => setApplyAdditionalLeaveModal(false)}
        />
      )}
      <div className="overflow-hidden flex-1">
        <div className="px-10 pt-8 pb-4 ">
          <div className="flex items-center justify-between">
            <div>
              <h2>{isEmployee ? "Overview" : "My Leave"}</h2>
              <p>
                {hrOrManager
                  ? "Over view of your leave"
                  : "Manage all employee regularization"}
              </p>
            </div>
            <div className="flex gap-3 items-center">
              <div className="">
                <button
                  className="btn bg-[#3466E7] max-h-[38px] text-sm"
                  onClick={applyleave}
                >
                  <span>
                    <i className="ico-plus text-white mr-1 h-3.5 w-3.5"></i>
                  </span>
                  {isLeaveTabView ? "Apply Leave" : "Request Additional Leave"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-outer pb-6 mr-10 relative overflow-hidden border-b border-gray-200">
          <Swiper
            modules={[Navigation]}
            spaceBetween={16}
            slidesPerView={"auto"}
            navigation
            updateOnWindowResize
          >
            {leaveBalances &&
              leaveBalances.map((item, index) => {
                return (
                  <SwiperSlide key={`key${index}_swiper_slide`}>
                    <LeaveItem leave={item} key={`key${index}_swiper`} />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>

        <div className="flex flex-col">
          <div className="max-w-full">
            <div className="flex items-center justify-between  border-b-2">
              <div className="flex items-center gap-12 pl-4 pt-6">
                <button
                  className={
                    isLeaveTabView
                      ? "tabs active-tabsnew text-blue-800 font-medium"
                      : "tabs"
                  }
                  onClick={() => toggleTab(LEAVE_VIEW)}
                >
                  Leave
                </button>
                <button
                  className={
                    isAdditionalLeaveTab
                      ? "tabs active-tabsnew text-blue-800 font-medium"
                      : "tabs"
                  }
                  onClick={() => toggleTab(ADDITIONAL_LEAVE_VIEW)}
                >
                  Additional Leave
                </button>
              </div>
            </div>

            <div className="py-4 px-10">
              <h2 className="text-base text-gray-800 font-semibold">
                Leave Request
              </h2>
              <div className="flex justify-between">
                <div className="flex  gap-3 flex-wrap pt-2.5 ">
                  {isAdditionalLeaveTab ? (
                    <DatePicker
                      placeholder="Leave Date"
                      onChange={(date) =>
                        setAppliedDate(
                          date ? dayjs(date).format("YYYY-MM-DD") : null
                        )
                      }
                      value={appliedDate ? dayjs(appliedDate) : null}
                    />
                  ) : (
                    <RangePicker
                      className="w-[300px] h-10"
                      placeholder={["From Date", "To Date"]}
                      onChange={handleDateRangeChange}
                      value={dateRange?.[0] ? dateRange : []}
                      format="YYYY-MM-DD"
                    />
                  )}

                  <Select
                    className="w-[200px]"
                    options={
                      leaveBalances &&
                      leaveBalances.map((leave, index) => ({
                        label: leave.leave_name,
                        value: leave.leave_type_id,
                        key: `leave_balance_${index}`,
                      }))
                    }
                    placeholder="Type: All"
                    onChange={(e) => {
                      setLeaveType(e);
                    }}
                    value={
                      leaveType
                        ? { label: leaveType.label, value: leaveType.value }
                        : { label: "Type: All", value: "" }
                    }
                  />
                  <Select
                    placeholder="Status: All"
                    options={[
                      { label: "Approved", value: 1 },
                      { label: "Pending", value: 2 },
                      { label: "Declined", value: 3 },
                      { label: "Cancelled", value: 4 },
                    ]}
                    onChange={(e) => {
                      setLeaveStatus(e);
                    }}
                    value={
                      leaveStatus
                        ? {
                            label: leaveStatus.label,
                            value: leaveStatus.value,
                          }
                        : {
                            label: "Status: All",
                            value: "",
                          }
                    }
                  />
                  {isFilterApplied ? (
                    <button
                      className="flex h-10 items-center font-medium cursor-pointer text-blue-800"
                      onClick={clearAllFilters}
                    >
                      Clear Filters
                    </button>
                  ) : null}
                </div>
                <div className="flex flex-wrap gap-3 pt-2.5">
                  <button
                    type="button"
                    className="btn btn--border h-10"
                    onClick={handleRefetchData}
                  >
                    <span className="material-symbols-outlined">refresh</span>
                  </button>
                  <Search onChange={searchHandle} />
                  {/* <div>
                    <button className="btn btn--border text-[#374151] max-h-[38px] max-w-[127px] text-sm">
                      <span>
                        <i className="ico-download text-[#6B7280] mr-1 h-3.5 w-3.5"></i>
                      </span>
                      Download
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {isLoading ? (
            <PageLoader />
          ) : Array.isArray(leaveData) && leaveData.length > 0 ? (
            <LeaveRequestList leaveData={leaveData} />
          ) : (
            <EmptyState />
          )}

          {!!(Array.isArray(leaveData) && leaveData.length > 0) && (
            <Pagination
              page={{
                pageNo,
                setPageNo,
                pageSize,
                setPageSize: handleSetPageSize,
                totalRecords,
              }}
            />
          )}
        </div>
      </div>
    </MyLeaveContext.Provider>
  );
};

export default MyLeaves;
