import React, { useState, useEffect } from "react";
import Search from "../../../../Header/Search/Search";
import ApplicableForSelectList from "../ApplicableForSelectListingComp/ApplicableForSelectList";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../../../PageLoader/SpinnerLoader";
import { shiftTagTypes } from "../../../../../Consts/consts";

const emptyState = <p>No Data Found...</p>;

const ApplicableForDepartmentSelectComp = ({
  handleChangeApplicableFor,
  applicableForData,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const [departmentList, setDepartmentList] = useState([]);
  const [loaderComponent, setLoaderComponent] = useState(<SpinnerLoader />);
  const [searchValue, setSearchValue] = useState("");
  const [baseUrl, setBaseUrl] = useState("employee/getDepartmentList");

  const getSearchValue = (data) => {
    setSearchValue(data);
  };

  const fetchDepartmentList = async () => {
    setLoaderComponent(<SpinnerLoader />);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(baseUrl, {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setDepartmentList(data.result);
          setLoaderComponent(null);
        } else {
          setLoaderComponent(emptyState);
        }
      })
      .catch((err) => {
        // console.log(err);
        setLoaderComponent(emptyState);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    let baseUrl = `employee/getDepartmentList`;
    if (searchValue) {
      const delayTimer = setTimeout(() => {
        baseUrl = baseUrl + `?filter=${searchValue}`;
        setBaseUrl(baseUrl);
      }, 600);
      return () => {
        clearTimeout(delayTimer);
      };
    }
  }, [searchValue]);

  useEffect(() => {
    fetchDepartmentList();
  }, [baseUrl]);

  const handleSelectAll = (e) => {
    if (departmentList.length) {
      departmentList.forEach((i, index) => {
        handleChangeApplicableFor({
          status: e.target.checked,
          referenceId: i.id,
          tag: shiftTagTypes.department,
          title: i.department_name,
          photo: i.photo || null,
          uniqueId: `department_uniq${index}`,
        })
      })
    }
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-3">
        <input type="checkbox" onClick={handleSelectAll} />
        <p className="flex items-center gap-2">
          <Search onChange={getSearchValue} mx={"0"} />
        </p>
      </div>
      {loaderComponent ? (
        <div className="flex justify-center items-center h-[150px]">
          {loaderComponent}
        </div>
      ) : null}
      {departmentList.length
        ? departmentList.map((item, index) => {
            const isExist = !!applicableForData.find(
              (data) =>
                data.referenceId === item.id && data.tag === "department"
            );

            return (
              <ApplicableForSelectList
                handleChangeApplicableFor={handleChangeApplicableFor}
                tag={shiftTagTypes.department}
                referenceId={item.id}
                title={item.department_name}
                isExist={isExist}
                key={`department_uniq${index}`}
                uniqueId={`department_uniq${index}`}
              />
            );
          })
        : null}
    </div>
  );
};

export default ApplicableForDepartmentSelectComp;
