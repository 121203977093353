import React, { useState } from "react";
import Select from "react-select";
import ShiftCalenderTable from "../../../components/Attendance/ManageShift/ShiftCalendar/ShiftCalenderTable";
import MiniShiftOverviewBar from "../../../components/Attendance/ManageShift/ShiftCalendar/MiniShiftOverviewBar";
import formatDate from "../../../utils/FormatDate/formatDate";
import moment from "moment";
import EmptyState from "../../../components/EmptyState";
import PageLoader from "../../../components/PageLoader";
import Search from "../../../components/Header/Search/Search";
import {
  useGetLocationList,
  useGetDepartmentList,
  useGetDesignationList,
} from "../../../queries/employeeQueries";
import {
  useShiftList,
  useEmployeeShiftList,
} from "../../../queries/attendanceQuries";
import { generateDates } from "../../../utils/FormatDate/generateDates";

const ShiftCalendar = () => {
  const [firstDate, setFirstDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [lastDate, setLastDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [pageNoAndSize, setPageNoAndSize] = useState({
    pageNo: 1,
    pageSize: 10,
  });

  const [empFilters, setEmpFilters] = useState({
    filter: "",
    location: null,
    designation: null,
    department: null,
  });

  const { data: locationListData } = useGetLocationList();
  const { data: departmentListData } = useGetDepartmentList();
  const { data: designationListData } = useGetDesignationList();
  const { data: shiftListData } = useShiftList();

  const { data: employeeShiftData, isLoading } = useEmployeeShiftList({
    ...empFilters,
    location: empFilters.location?.value || "",
    department: empFilters.department?.value || "",
    designation: empFilters.designation?.value || "",
    fromDate: firstDate,
    toDate: lastDate,
    pageNo: pageNoAndSize.pageNo,
    pageSize: pageNoAndSize.pageSize,
  });

  const formatedFirstDay = formatDate(firstDate).dateString2;
  const formatedLastDay = formatDate(lastDate).dateString2;

  const locationList = Array.isArray(locationListData?.result) ? locationListData.result : [];
  const departmentList = Array.isArray(departmentListData?.result) ? departmentListData.result : [];
  const designationList = Array.isArray(designationListData?.result) ? designationListData.result : [];
  const shiftList = Array.isArray(shiftListData?.result) ? shiftListData.result : [];

  const handleSetEmpFilter = (value, source) => {
    setEmpFilters((prev) => ({ ...prev, [source]: value }));
  };

  const handleChangeDate = (type) => {
    if (type === "nextMonth") {
      const nextMonth = moment(firstDate).add(1, "month").format("YYYY-MM-DD");
      setFirstDate(nextMonth);
      setLastDate(moment(nextMonth).endOf("month").format("YYYY-MM-DD"));
    } else {
      const prevMonth = moment(firstDate)
        .subtract(1, "month")
        .format("YYYY-MM-DD");
      setFirstDate(prevMonth);
      setLastDate(moment(prevMonth).endOf("month").format("YYYY-MM-DD"));
    }
  };

  const handleClearFilters = () => {
    setEmpFilters({
      filter: "",
      location: null,
      designation: null,
      department: null,
    });
  };

  const isFilterApplied = Object.values(empFilters).some((value) => value);

  const datesCol = generateDates(firstDate, lastDate);

  const screenWidth = window.innerWidth - 326;
  const totalRecords = employeeShiftData?.totalRecords || 0;
  const totalPages = Math.ceil(totalRecords / pageNoAndSize.pageSize) || 1;

  const nextPage = () => {
    if (pageNoAndSize.pageNo < totalPages) {
      setPageNoAndSize((prev) => ({ ...prev, pageNo: prev.pageNo + 1 }));
    }
  };

  const previousPage = () => {
    if (pageNoAndSize.pageNo > 1) {
      setPageNoAndSize((prev) => ({ ...prev, pageNo: prev.pageNo - 1 }));
    }
  };

  return (
    <div className={`flex flex-col relative`} style={{ width: screenWidth }}>
      <div className="p-10 flex flex-col gap-5">
        <div>
          <h1 className="text-black font-semibold text-base">Shift Calendar</h1>
          <p>Manage and view shifts</p>
        </div>

        <div className="flex justify-between">
          <div className="flex gap-3">
            <Select
              placeholder="Designation"
              options={designationList.map((i) => ({
                label: i.designation_title,
                value: i.id,
              }))}
              value={empFilters.designation}
              onChange={(e) => handleSetEmpFilter(e, "designation")}
            />

            <Select
              placeholder="Department"
              options={departmentList.map((i) => ({
                label: i.department_name,
                value: i.id,
              }))}
              value={empFilters.department}
              onChange={(e) => handleSetEmpFilter(e, "department")}
            />

            <Select
              placeholder="Office"
              options={locationList.map((i) => ({
                label: i.location_name,
                value: i.id,
              }))}
              value={empFilters.location}
              onChange={(e) => handleSetEmpFilter(e, "location")}
            />

            <Search onChange={(e) => handleSetEmpFilter(e || "", "filter")} />

            {isFilterApplied && (
              <p
                className="text-blue-600 font-medium pt-2 cursor-pointer"
                onClick={handleClearFilters}
              >
                Clear filter
              </p>
            )}
          </div>

          <div className="flex gap-4">
            <div className="flex gap-3 items-center">
              <button onClick={() => handleChangeDate("prevMonth")}>
                <i className="ico-left"></i>
              </button>
              <p className="text-black font-medium">
                {formatedFirstDay} - {formatedLastDay}
              </p>
              <button onClick={() => handleChangeDate("nextMonth")}>
                <i className="ico-right"></i>
              </button>
            </div>
            <div>
              <button className="btn btn--border">
                <i className="ico-download"></i>
                Download
              </button>
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-2 items-center">
          <button className="btn btn--border" onClick={previousPage}>
            Previous
          </button>
          <span className="text-gray-800 font-medium">
            {pageNoAndSize.pageNo} / {totalPages} pages
          </span>
          <button className="btn btn--border" onClick={nextPage}>
            Next
          </button>
        </div>
      </div>

      <div>
        {isLoading ? (
          <PageLoader />
        ) : employeeShiftData?.result?.length ? (
          <ShiftCalenderTable data={employeeShiftData.result} datesCol={datesCol} />
        ) : (
          <EmptyState />
        )}
      </div>

      <div className="fixed bottom-0 w-full mt-5 bg-white">
        <MiniShiftOverviewBar shiftList={shiftList} />
      </div>
    </div>
  );
};

export default ShiftCalendar;
