import React, { useState, useEffect } from "react";
import AddAutoIncrementLeaveBalance from "./AddAutoIncrementLeaveBalance";

const AutoIncrementLeaveDetails = ({ details, months, leaveTypeId, refreshDetails, leaveTypeName }) => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  
  useEffect(() => {
    refreshDetails();
  }, [selectedMonth]);

  const openModal = (index) => {
    setSelectedMonth(index);
  };

  const closeModal = () => {
    setSelectedMonth(null);
    refreshDetails(); 
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <>
      {months.map((month, index) => {
        const monthDetails = details.find(
          (detail) => detail.updated_month === index + 1
        );
        const isCurrentMonth = index === new Date().getMonth(); 
        const isIncremented = monthDetails && monthDetails.increment_value;
        const formattedTime = monthDetails 
          ? new Date(monthDetails.created_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) 
          : "-";
        const formattedDate = monthDetails 
          ? formatDate(monthDetails.created_date)
          : "-";

        return (
          <tr key={index} className="hover:bg-gray-50">
            <td className="px-6 py-3">{month}</td>
            <td className="px-6 py-3">{new Date().getFullYear()}</td>
            <td className="px-6 py-3">
              {formattedDate}
            </td>
            <td className="px-6 py-3">
              {formattedTime}
            </td>
            <td className="px-6 py-3">{monthDetails ? monthDetails.updated_type : "-"}</td>
            <td className="px-6 py-3">
              <span className={isIncremented ? "status status--green" : "status status--red"}>
                {isIncremented ? "Incremented" : "Non-incremented"}
              </span>
            </td>
            <td className="px-6 py-3 text-right">
              {isCurrentMonth && !isIncremented && ( 
                <button
                  className="btn"
                  onClick={() => openModal(index)}
                >
                  <p>Manual Increment</p>
                </button>
              )}
            </td>
          </tr>
        );
      })}
      {selectedMonth !== null && (
        <AddAutoIncrementLeaveBalance
          open={selectedMonth !== null}
          close={closeModal}
          leaveTypeId={leaveTypeId}
          updatedMonth={selectedMonth + 1}
          refreshDetails={refreshDetails}
          leaveTypeName={leaveTypeName} 
        />
      )}
    </>
  );
};

export default AutoIncrementLeaveDetails;
