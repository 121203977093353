export const getFormErrMsg = (field, errors) => {
  if (field && errors?.[field]?.message) {
    return (
      <p className="text-red-600 font-medium text-xs ml-1 my-1" role="alert">
        {errors?.[field]?.message}
      </p>
    );
  }
  return "";
};

export const formatSearchParams = (obj) => {
  try {
    if (typeof obj === "object") {
      const params = {};
      Object.keys(obj).forEach((key) => {
        const value = obj?.[key];
        if (value) {
          params[key] = value;
        }
      });
      return params;
    }
  } catch (err) {}
  return {};
};

export const minutesToMillisecond = (minutes) => {
  if (typeof minutes === "number" && !isNaN(minutes)) {
    return minutes * 60 * 1000;
  } else {
    throw new Error("minutes must be a valid number");
  }
};
