import React, { useState, useEffect } from "react";
import Search from "../../../../Header/Search/Search";
import ApplicableForSelectList from "../ApplicableForSelectListingComp/ApplicableForSelectList";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../../../PageLoader/SpinnerLoader";
import { shiftTagTypes } from "../../../../../Consts/consts";

const emptyState = <p>No Data Found...</p>;

const ApplicableForEmployeeSelectComp = ({
  handleChangeApplicableFor,
  applicableForData,
}) => {
  const axiosPrivate = useAxiosPrivate();

  const [loaderComponent, setLoaderComponent] = useState(<SpinnerLoader />);
  const [getEmployee, setGetEmployee] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const [pageNo, setPageNo] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const [employeeSearchUrl, setEmployeeSearchUrl] = useState(
    `employee/search?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC`
  );

  const fetchEmployeeData = async () => {
    setLoaderComponent(<SpinnerLoader />);
    setGetEmployee(null);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(employeeSearchUrl, {
        signal: controller.signal,
      })
      .then((response) => {
        // isMounted && console.log(response.data);
        const res = response?.data;
        if (Number(res.statusCode) === 200) {
          setTimeout(() => {
            setGetEmployee(res.employee);
            setTotalPages(Math.ceil(res.totalRecords/pageSize));
            setLoaderComponent(null);
          }, 500);
        } else if (
          Number(res.statusCode) === 400 &&
          res.description === "End of pagination "
        ) {
          setPageNo(1);
        } else if (Number(res.statusCode) === 400) {
          setGetEmployee(null);
          setLoaderComponent(emptyState);
        }
      })
      .catch((err) => {
        console.error(err);
        setGetEmployee(null);
        setLoaderComponent(emptyState);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const getSearchValue = (data) => {
    setSearchValue(data);
  };

  useEffect(() => {
    let baseUrl = `employee/search?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC`;

    if (searchValue) {
      const delayTimer = setTimeout(() => {
        setPageNo(1);
        baseUrl = baseUrl + `&filter=${searchValue}`;
        setEmployeeSearchUrl(baseUrl);
      }, 600);
      return () => {
        clearTimeout(delayTimer);
      };
    }
    setEmployeeSearchUrl(baseUrl);
  }, [searchValue, pageNo]);

  useEffect(() => {
    fetchEmployeeData();
  }, [employeeSearchUrl]);

  const nextPage = () => {
    if (pageNo < totalPages) {
      setPageNo(pageNo + 1);
    }
  };

  const previousPage = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };
  

  const handleSelectAll = (e) => {
    if (getEmployee) {
      getEmployee.forEach((i) => {
        handleChangeApplicableFor({
          status: e.target.checked,
          referenceId: i.employee_id,
          tag: shiftTagTypes.employee,
          title: `${i.first_name || ""} ${i.last_name || ""}`,
          photo: i.photo || null,
          uniqueId: i.employee_id,
        })
      })
    }
  }
  return (
    <div className="flex w-full">
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-3">
          <input type="checkbox" onClick={handleSelectAll} />
          <div className="flex items-center gap-2">
            <Search onChange={getSearchValue} mx={"0"} />
          </div>
        </div>
        {loaderComponent ? (
          <div className="flex justify-center items-center h-[150px]">
            {loaderComponent}
          </div>
        ) : null}
        {getEmployee &&
          getEmployee.map((item, index) => {
            const isExist = !!applicableForData.find(
              (data) =>
                data.referenceId === item.employee_id && data.tag === "employee"
            );
            return (
              <ApplicableForSelectList
                key={`employee_${index}`}
                referenceId={item.employee_id}
                title={`${item.first_name || ""} ${item.last_name || ""}`}
                photo={item.photo || null}
                handleChangeApplicableFor={handleChangeApplicableFor}
                tag={shiftTagTypes.employee}
                isExist={isExist}
                uniqueId={item.employee_id}
                isEmployeeList={true}
              />
            );
          })}
      </div>
      <div className="flex gap-3 justify-center items-end">
        <button onClick={previousPage}>
          <i className="ico-left text-gray-400 text-xs" />
        </button>
        <span className="font-medium text-gray-800">
          {pageNo}/{totalPages}
        </span>
        <button onClick={nextPage}>
          <i className="ico-right text-gray-400 text-xs" />
        </button>
      </div>
    </div>
  );
};

export default ApplicableForEmployeeSelectComp;
