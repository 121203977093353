import { useState, useEffect, useRef } from "react";
import Select, { components } from "react-select";
import EmployeeModuleList from "../../../../components/Employee/EmployeeModuleList/EmployeeModuleList";
import Search from "../../../../components/Header/Search";
import BulkUploadEmployeeModal from "../CreateEmployee/BulkUploadEmployeeModal/BulkUploadEmployeeModal";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../../utils/userRoleAuth/userRoleAuth";
import "react-responsive-modal/styles.css";
import AddNewEmployeeModal from "../../../../components/Employee/AddNewEmployeeModal/AddNewEmployeeModal";
import Pagination from "../../../../components/Pagination/Pagination";
import BulkLeaveSettingModal from "../../../../components/Employee/BulkLeaveSettingModal/BulkLeaveSettingModal";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import PageLoader from "../../../../components/PageLoader/PageLoader";
import { personRoundedIcon } from "../../../../Icons/Icons";
import AssignLineManagerModal from "../../../../components/Employee/AssignLineManagerModal/AssignLineManagerModal";
import AssignHRModal from "../../../../components/Employee/AssignHRModal/AssignHRModal";
import {
  useEmployeeList,
  useGetLocationList,
  useGetDepartmentList,
  useGetDesignationList,
  useGetLineManagerList,
  useGetHrList,
} from "../../../../queries/employeeQueries";
import userRoles from "../../../../config/userRoles";
import {
  employementStatus as employementStatusOptions,
  roleOptions,
} from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import { useLeavePolicyList } from "../../../../queries/leaveQueries";

const FILTER_CONSTS = {
  filter: "filter",
  hr: "hr",
  lineManager: "lineManager",
  accountStatus: "accountStatus",
  office: "office",
  designation: "designation",
  department: "department",
  pageSize: "pageSize",
  pageNo: "pageNo",
  roleId: "roleId",
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};
const defaultLocationOption = { location_name: "Office: All", id: null };
const defaultDesignationOption = {
  designation_title: "Designation: All",
  id: null,
};
const defaultDepartmentOption = {
  department_name: "Department: All",
  id: null,
};
const defaultLineManagerOption = {
  line_manager_name: "Line Manager: All",
  id: null,
};
const defaultHROption = {
  hr_name: "HR: All",
  id: null,
};

const filterInitialState = {
  filter: "",
  hr: "",
  lineManager: "",
  accountStatus: "",
  office: "",
  designation: "",
  department: "",
  roleId: "",
};

const EmployeeList = () => {
  const user = useSelector((state) => state.user.auth);
  const isHrAuth = userRoleAuth(user, userRoles.HR);
  const isAdmin = userRoleAuth(user, userRoles.TENANTADMIN);
  const IsHrOrAdminRole = isHrAuth || isAdmin;

  localStorage.setItem("isContinue", "true");

  const selectBoxRef = useRef({
    "Employement Status": "",
    Ofiice: "",
    Department: "",
    Designation: "",
    "Line Manager": "",
    HR: "",
    Role: "",
  });

  const [isAddEmployeeModal, setIsAddEmployeeModal] = useState(false);
  const [pageNoAndSize, setPageNoAndSize] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  const [empFilters, setEmpFilters] = useState(filterInitialState);
  const [empSearchParams, setEmpSearchParams] = useState({});
  const [isBulkUploadEmployee, setIsBulkUploadEmployee] = useState(false);
  const [isBulkLeaveSettingModal, setIsBulkLeaveSettingModal] = useState(false);
  const [isAssignHRModalOpen, setAssignHRModalOpen] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [isAssignLineManagerModalOpen, setAssignLineManagerModalOpen] =
    useState(false);

  const handleSetEmpFilter = (value, source) => {
    setEmpFilters((prev) => ({
      ...prev,
      [source]: value,
    }));
  };

  const {
    data: employeeData,
    isLoading: isEmployeeDataLoading,
    refetch: fetchEmployeeData,
  } = useEmployeeList({
    ...empSearchParams,
    pageNo: pageNoAndSize.pageNo,
    pageSize: pageNoAndSize.pageSize,
    sortOrder: "DESC",
  });
  const { data: getLeavePolicyList } = useLeavePolicyList();
  const { data: getLocationList } = useGetLocationList();
  const { data: getDepartmentList } = useGetDepartmentList();
  const { data: getDesignationList } = useGetDesignationList();
  const { data: getLineManagerList } = useGetLineManagerList();
  const { data: getHRList } = useGetHrList();

  const locationList = Array.isArray(getLocationList?.result)
    ? [defaultLocationOption, ...getLocationList?.result]
    : [];

  const totalRecords = employeeData?.totalRecords || 0;
  const employeeList = employeeData?.employee || null;

  const leavePolicyList = Array.isArray(getLeavePolicyList?.policy_list)
    ? getLeavePolicyList?.policy_list
    : [];

  const departmentList = Array.isArray(getDepartmentList?.result)
    ? [defaultDepartmentOption, ...getDepartmentList?.result]
    : [];

  const designationList = Array.isArray(getDesignationList?.result)
    ? [defaultDesignationOption, ...getDesignationList?.result]
    : [];

  const managerList = Array.isArray(getLineManagerList?.result)
    ? [defaultLineManagerOption, ...getLineManagerList.result]
    : [];
  const hrList = Array.isArray(getHRList?.result)
    ? [defaultHROption, ...getHRList.result]
    : [];

  const openAddEmployeeModal = () => {
    setIsAddEmployeeModal(true);
  };

  useEffect(() => {
    const params = {};
    const empFiltersKeys = Object.keys(empFilters);

    empFiltersKeys.forEach((key) => {
      const value = empFilters?.[key];
      if (value) params[key] = value;
    });

    handleSetPageNoAndSize(1, FILTER_CONSTS.pageNo);
    setEmpSearchParams(params);
    const delayTimer = setTimeout(() => {
      setEmpSearchParams(params);
    }, 500);

    return () => {
      clearTimeout(delayTimer);
    };
  }, [empFilters]);

  const isFilterApplied =
    empFilters?.filter ||
    empFilters?.department ||
    empFilters?.designation ||
    empFilters.office ||
    empFilters.accountStatus ||
    empFilters.lineManager ||
    empFilters.hr ||
    empFilters.roleId;

  const handleClearFilters = () => {
    const selectBoxRefKeys = Object.keys(selectBoxRef.current);
    selectBoxRefKeys.forEach((item) => {
      try {
        if (selectBoxRef?.current?.[item]) {
          selectBoxRef?.current?.[item]?.setValue({
            label: `${item}: All`,
            value: "",
          });
        }
      } catch (e) {}
    });
    setEmpFilters(filterInitialState);
  };

  const handleSelectEmployee = (employee, status) => {
    if (!employee && typeof status === "boolean") {
      if (status) {
        setSelectedEmployees(employeeList);
      } else {
        setSelectedEmployees([]);
      }
    } else if (employee) {
      if (status) {
        setSelectedEmployees((prev) => {
          const isExists = prev?.find(
            (i) => i?.employee_id === employee?.employee_id
          );
          if (isExists) {
            return prev;
          } else {
            return [...prev, employee];
          }
        });
      } else {
        setSelectedEmployees((prev) =>
          prev.filter((i) => i.employee_id !== employee.employee_id)
        );
      }
    }
  };

  const handleSetPageNoAndSize = (e, source) => {
    if (source === FILTER_CONSTS.pageSize) {
      setPageNoAndSize({
        pageNo: 1,
        pageSize: e,
      });
    } else {
      setPageNoAndSize((prev) => ({
        ...prev,
        [source]: e,
      }));
    }
  };

  return (
    <>
      {isAssignLineManagerModalOpen && (
        <AssignLineManagerModal
          open={isAssignLineManagerModalOpen}
          close={() => setAssignLineManagerModalOpen(false)}
          selectedEmployees={selectedEmployees}
        />
      )}
      {isAssignHRModalOpen && (
        <AssignHRModal
          open={isAssignHRModalOpen}
          close={() => setAssignHRModalOpen(false)}
          selectedEmployees={selectedEmployees}
        />
      )}
      <div className="flex flex-col w-full">
        <div>
          {isAddEmployeeModal && (
            <AddNewEmployeeModal
              open={isAddEmployeeModal}
              close={() => setIsAddEmployeeModal(false)}
              fetchEmployeeData={fetchEmployeeData}
            />
          )}
          {isBulkUploadEmployee && (
            <BulkUploadEmployeeModal
              open={isBulkUploadEmployee}
              close={() => setIsBulkUploadEmployee(false)}
              fetchEmployeeData={fetchEmployeeData}
            />
          )}
          {isBulkLeaveSettingModal && (
            <BulkLeaveSettingModal
              open={isBulkLeaveSettingModal}
              close={() => setIsBulkLeaveSettingModal(false)}
            />
          )}
          <div className="px-10 py-4 flex flex-col justify-between">
            <div className="flex mb-2 justify-between">
              <div>
                {" "}
                <h2>Employee List</h2>
                <p className="pb-4">Manage all employee on your organization</p>
              </div>
              <div className=" flex flex-wrap gap-2 justify-end select-none">
                {IsHrOrAdminRole ? (
                  <div
                    className={`mr-3 flex gap-3 ${
                      selectedEmployees.length ? "" : "opacity-50"
                    }`}
                  >
                    <button
                      onClick={() => setAssignLineManagerModalOpen(true)}
                      className="btn btn--border flex items-center gap-1"
                      disabled={selectedEmployees.length ? false : true}
                    >
                      {personRoundedIcon} Manage line manager
                    </button>
                  </div>
                ) : null}
                {IsHrOrAdminRole ? (
                  <div
                    className={`mr-3 flex gap-3 ${
                      selectedEmployees.length ? "" : "opacity-50"
                    }`}
                  >
                    <button
                      onClick={() => setAssignHRModalOpen(true)}
                      className="btn btn--border flex items-center gap-1"
                      disabled={selectedEmployees.length ? false : true}
                    >
                      {personRoundedIcon} Manage HR
                    </button>
                  </div>
                ) : null}
                {IsHrOrAdminRole ? (
                  <button
                    onClick={() => setIsBulkLeaveSettingModal(true)}
                    className="btn btn--border mr-3 text-[14px]"
                  >
                    <span>
                      <i className="ico-clock1 text-black mr-1"></i>
                    </span>
                    Bulk Leave Setting
                  </button>
                ) : null}
                {IsHrOrAdminRole ? (
                  <button
                    onClick={() => setIsBulkUploadEmployee(true)}
                    className="btn btn--border mr-3 text-[14px]"
                  >
                    <span>
                      <i className="ico-plus text-black mr-1"></i>
                    </span>
                    Bulk Upload Employee
                  </button>
                ) : null}

                {IsHrOrAdminRole ? (
                  <button
                    onClick={openAddEmployeeModal}
                    className="btn text-[14px]"
                  >
                    <span>
                      <i className="ico-plus text-white mr-1"></i>
                    </span>
                    Add Employee
                  </button>
                ) : null}
                {/* <Link to="/employee/manage-employee/create-employee">
                      <button className="btn text-[14px]">
                        <span>
                          <i className="ico-plus text-white mr-1"></i>
                        </span>
                        Add Employee
                      </button>
                    </Link> */}
              </div>
            </div>

            <div className="flex flex-wrap gap-2 justify-between">
              <div className="flex flex-wrap gap-3">
                <Select
                  ref={(ele) => (selectBoxRef.current["Designation"] = ele)}
                  placeholder={"Designation: All"}
                  options={designationList.map((i) => ({
                    label: i.designation_title,
                    value: i.id,
                  }))}
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) =>
                    handleSetEmpFilter(e?.value, FILTER_CONSTS.designation)
                  }
                />
                <Select
                  ref={(ele) => (selectBoxRef.current["Department"] = ele)}
                  placeholder={"Department: All"}
                  options={departmentList.map((i) => ({
                    label: i.department_name,
                    value: i.id,
                  }))}
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => {
                    handleSetEmpFilter(e.value, FILTER_CONSTS.department);
                  }}
                />
                <Select
                  ref={(ele) => (selectBoxRef.current["Ofiice"] = ele)}
                  placeholder={"Office: All"}
                  options={locationList.map((i) => ({
                    label: i.location_name,
                    value: i.id,
                  }))}
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => {
                    handleSetEmpFilter(e.value, FILTER_CONSTS.office);
                  }}
                />
                <Select
                  ref={(ele) =>
                    (selectBoxRef.current["Employement Status"] = ele)
                  }
                  placeholder={"Employement Status:  All"}
                  options={[
                    { label: "Employeement Status: All", value: null },
                    ...employementStatusOptions,
                  ]}
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => {
                    handleSetEmpFilter(e.value, FILTER_CONSTS.accountStatus);
                  }}
                />
                <Select
                  ref={(ele) => (selectBoxRef.current["Line Manager"] = ele)}
                  placeholder={"Line Manager: All"}
                  options={managerList.map((i) => ({
                    label: i.line_manager_name,
                    value: i.id,
                  }))}
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => {
                    handleSetEmpFilter(e.value, FILTER_CONSTS.lineManager);
                  }}
                />
                <Select
                  ref={(ele) => (selectBoxRef.current["HR"] = ele)}
                  placeholder={"HR: All"}
                  options={hrList.map((i) => ({
                    label: i.hr_name,
                    value: i.id,
                  }))}
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => {
                    handleSetEmpFilter(e.value, FILTER_CONSTS.hr);
                  }}
                />
                {IsHrOrAdminRole && (
                  <Select
                    ref={(ele) => (selectBoxRef.current["Role"] = ele)}
                    placeholder={"Role: All"}
                    options={[
                      { label: "Role: All", value: null },
                      ...roleOptions,
                    ]}
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => {
                      handleSetEmpFilter(e.value, FILTER_CONSTS.roleId);
                    }}
                  />
                )}

                {!!isFilterApplied && (
                  <button
                    className="text-blue-600 cursor-pointer"
                    onClick={handleClearFilters}
                  >
                    Clear filters
                  </button>
                )}
              </div>
              <div>
                {" "}
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="btn btn--border h-[38px]"
                    onClick={fetchEmployeeData}
                  >
                    <span className="material-symbols-outlined">refresh</span>
                  </button>
                  <div>
                    <Search
                      onChange={(e) =>
                        handleSetEmpFilter(e, FILTER_CONSTS.filter)
                      }
                    />
                  </div>
                  {/* <a href="#" className="btn btn--border text-[14px]">
                  <span>
                    <i className="ico-download mr-1"></i>
                  </span>
                  Download
                </a> */}
                </div>
              </div>
            </div>
          </div>
          {isEmployeeDataLoading ? (
            <PageLoader />
          ) : Array.isArray(employeeList) && employeeList.length > 0 ? (
            <EmployeeModuleList
              data={employeeList}
              leavePolicyList={leavePolicyList}
              handleSelectEmployee={handleSelectEmployee}
              selectedEmployees={selectedEmployees}
            />
          ) : (
            <EmptyState />
          )}
          {Array.isArray(employeeList) && employeeList.length > 0 && (
            <Pagination
              page={{
                pageNo: pageNoAndSize.pageNo,
                setPageNo: (e) =>
                  handleSetPageNoAndSize(e, FILTER_CONSTS.pageNo),
                pageSize: pageNoAndSize.pageSize,
                setPageSize: (e) =>
                  handleSetPageNoAndSize(e, FILTER_CONSTS.pageSize),
                totalRecords,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EmployeeList;
