import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { closeIcon } from "../../../Icons/Icons";
import Select from "react-select";
import Avatar from "../../Avatar";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import formatAvatarLetter from "../../../utils/FormatAvatarLetter/formatAvatarLetters"

const AssignLineManagerModal = ({ open, close, selectedEmployees }) => {
  const axiosPrivate = useAxiosPrivate();
  const [managerList, setManagerList] = useState([]);
  const [selectedLineManager, setSelectedLineManager] = useState(null);

  const fetchLineManager = async () => {
    const controller = new AbortController();
    await axiosPrivate
      .get(
        "employee/search?pageNo=1&pageSize=100&sortOrder=DESC&role=Manager",
        {
          signal: controller.signal,
        }
      )
      .then((response) => {
        const res = response?.data;
        setManagerList(res.employee || []);
      })
      .catch((err) => {
        throw err;
      });

    return () => {
      controller.abort();
    };
  };

  useEffect(() => {
    fetchLineManager();
    if (selectedEmployees.length === 1 && selectedEmployees[0]?.line_manager_name) {
      const currentManager = {
        employee_id: selectedEmployees[0].line_manager_id,
        first_name: selectedEmployees[0].line_manager_name.split(" ")[0],
        last_name: selectedEmployees[0].line_manager_name.split(" ")[1] || "",
      };
      setSelectedLineManager(currentManager);
    } else {
      setSelectedLineManager(null);
    }
  }, [selectedEmployees]);

  const assignLineManager = async (reqObj) => {
    await axiosPrivate
      .put("employee/assignLineManager", { employeeList: reqObj })
      .then((res) => {
        if (res?.data?.status) {
          toast.success(
            res?.data?.message ||
              "Line manager updated for the selected employee(s)"
          );
          close();
        } else {
          toast.info(res?.data?.message || "Something went wrong... try again");
        }
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.error || "Failed to assign line manager(s)"
        );
      });
  };

  const onSubmit = () => {
    if (!selectedLineManager) {
      return toast.info("Select a line manager from the list");
    }
    const reqObj = selectedEmployees.map((employee) => ({
      employeeId: employee.employee_id,
      lineManagerId: selectedLineManager.employee_id,
    }));
    assignLineManager(reqObj);
  };

  return (
    <Modal open={open} onClose={close} center showCloseIcon={false}>
      <div className="w-[500px] flex flex-col">
        <div className="flex justify-between items-center gap-5">
          <h1 className="text-xl font-medium text-gray-800">
            Assign Line Manager
          </h1>
          <button onClick={close}>{closeIcon}</button>
        </div>
        <div className="py-2">
          <label>Select line manager</label>
          <Select
            options={managerList}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            getOptionValue={(option) => option.employee_id}
            value={selectedLineManager} 
            onChange={(selectedOption) =>
              setSelectedLineManager(selectedOption)
            }
           
            placeholder="Select Line Manager"
          />
        </div>
        <div>
          {selectedEmployees.map((i) => {
            const empShortName = `${i?.first_name} ${i?.last_name}`;
            const avatarLetter = formatAvatarLetter(empShortName);
            const empName = `${i.first_name || ""} ${i.last_name || ""}`;
            return (
              <div className="border rounded-3xl px-2 py-1 my-2 flex items-center gap-2 bg-gray-50">
                <Avatar width="8" height="8" title={avatarLetter} />
                <span className="text-gray-800">{empName}</span>
              </div>
            );
          })}
        </div>

        <div className="flex gap-3 justify-end">
          <button className="btn btn--border" onClick={close}>
            Close
          </button>
          <button className="btn" onClick={onSubmit}>
            Assign Line Manager
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AssignLineManagerModal;
