import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { formatSearchParams } from "../utils/helperFunctions";
import queryKeys from "./queryKeys";
const {
  addNewShift,
  assignShift,
  employeeShiftList,
  shiftList
} = queryKeys;
export const useEmployeeShiftList = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [employeeShiftList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("attendance/getEmployeeShiftList", {
        params: formatedSearchParams,
      });
      return data;
    },
  });
};


export const useShiftList = (params = {}) => {
    const formatedSearchParams = formatSearchParams(params)
    const axiosPrivate = useAxiosPrivate();
    return useQuery({
      queryKey: [shiftList, params],
      queryFn: async () => {
        const { data } = await axiosPrivate.get(
          "attendance/getShiftList",
          {
            params: formatedSearchParams,
          }
        );
        return data;
      },
    });
  }

  
  export const useAssignEmployeeShift = () => {
    const queryClient = useQueryClient();
    const axiosPrivate = useAxiosPrivate();
  
    return useMutation({
      mutationKey: [assignShift],
      mutationFn: async (payload) => {
        const { data } = await axiosPrivate.post(
          "/attendance/assignShift",
          payload
        );
        return data;
      },
      onSuccess: (data) => {
        if (data?.status) {
          queryClient.invalidateQueries([employeeShiftList]);
        } else {
          console.error(data?.message || "Failed to assign shift");
        }
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };
  

  export const useAddNewShift = () => {
    const queryClient = useQueryClient(); 
    const axiosPrivate = useAxiosPrivate(); 
  
    return useMutation({
      mutationKey: [addNewShift],
      mutationFn: async (payload) => {
        const { data } = await axiosPrivate.post("/attendance/addNewShift", payload);
        return data; 
      },
      onSuccess: (data) => {
        if (data?.status) {
          queryClient.invalidateQueries([shiftList]);
        } else {
          console.error(data?.message || "Failed to add new shift");
        }
        
      },
      onError: (error) => {   
        console.error(error);
      }
    });
  };
  
  