const queryKeys = {
  leaveTypeList: "leaveTypeList",
  leaveHrApprovalList: "leaveHrApprovalList",
  hrAdditionalLeaveApproval: "hrAdditionalLeaveApproval",
  hrLeaveApproval: "hrLeaveApproval",
  leaveHrAdditionalApprovalList: "leaveHrAdditionalApprovalList",
  leavePolicyList: "leavePolicyList",
  leaveManagerApprovalList: "leaveManagerApprovalList",
  additionalLeaveManagerApprovalList: "additionalLeaveManagerApprovalList",
  leaveDetails: "leaveDetails",
  additionalLeaveDetails: "additionalLeaveDetails",
  managerLeaveApproval: "managerLeaveApproval",
  managerAdditionalLeaveApproval: "managerAdditionalLeaveApproval",
  hrLeaveDecline: "hrLeaveDecline",
  hrAdditionalLeaveReject: "hrAdditionalLeaveReject",
  managerLeaveDecline: "managerLeaveDecline",
  managerAdditionalLeaveReject: "managerAdditionalLeaveReject",
  leaveBalances:"leaveBalances",
  leaveRequestList:"leaveRequestList",
  listConditionalLeaveType:"listConditionalLeaveType",
  additionalLeaveListForEmployee: "additionalLeaveListForEmployee",
  applyLeave: "applyLeave",
  applyAdditionalLeaves: "applyAdditionalLeaves",
  cancelLeaveRequest:"cancelLeaveRequest",
  assignShift:"assignShift",
  addNewShift:"addNewShift",
  employeeShiftList : "employee-shift-list",
  shiftList:"get-shift-list"
};

export default queryKeys;
