import React, { useState, useEffect } from "react";
import Search from "../../../../Header/Search/Search";
import ApplicableForSelectList from "../ApplicableForSelectListingComp/ApplicableForSelectList";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../../../PageLoader/SpinnerLoader";
import { shiftTagTypes } from "../../../../../Consts/consts";

const emptyState = <p>No Data Found...</p>;

const ApplicableForLocationsSelectComp = ({
  handleChangeApplicableFor,
  applicableForData,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const [locationList, setLocationList] = useState([]);
  const [loaderComponent, setLoaderComponent] = useState(<SpinnerLoader />);
  const [searchValue, setSearchValue] = useState("");
  const [baseUrl, setBaseUrl] = useState("employee/getLocationList");

  const getSearchValue = (data) => {
    setSearchValue(data);
  };

  const fetchLocationList = async () => {
    setLoaderComponent(<SpinnerLoader />);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(baseUrl, {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setLocationList(data.result);
          setLoaderComponent(null);
        } else {
          setLoaderComponent(emptyState);
        }
      })
      .catch((err) => {
        // console.log(err);
        setLoaderComponent(emptyState);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    let baseUrl = `employee/getLocationList`;
    if (searchValue) {
      const delayTimer = setTimeout(() => {
        baseUrl = baseUrl + `?filter=${searchValue}`;
        setBaseUrl(baseUrl);
      }, 600);
      return () => {
        clearTimeout(delayTimer);
      };
    }
  }, [searchValue]);

  useEffect(() => {
    fetchLocationList();
  }, [baseUrl]);

  const handleSelectAll = (e) => {
    if (locationList.length) {
      locationList.forEach((i, index) => {
        handleChangeApplicableFor({
          status: e.target.checked,
          referenceId: i.id,
          tag: shiftTagTypes.location,
          title: i.location_name,
          photo: i.photo || null,
          uniqueId: `location_uniq_${index}`,
        })
      })
    }
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-3">
        <input type="checkbox" onClick={handleSelectAll}/>
        <p className="flex items-center gap-2">
          <Search onChange={getSearchValue} mx={"0"} />
        </p>
      </div>
      {loaderComponent ? (
        <div className="flex justify-center items-center h-[150px]">
          {loaderComponent}
        </div>
      ) : null}
      {locationList.length
        ? locationList.map((item, index) => {
            const isExist = !!applicableForData.find(
              (data) => data.referenceId === item.id && data.tag === "location"
            );

            return (
              <ApplicableForSelectList
                handleChangeApplicableFor={handleChangeApplicableFor}
                tag={shiftTagTypes.location}
                referenceId={item.id}
                title={item.location_name}
                isExist={isExist}
                key={`location_uniq_${index}`}
                uniqueId={`location_uniq_${index}`}
              />
            );
          })
        : null}
    </div>
  );
};

export default ApplicableForLocationsSelectComp;
