import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { formatSearchParams } from "../utils/helperFunctions";

export const useEmployeeList = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["employee-list", params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("employee/search", {
        params: formatedSearchParams,
      });
      return data;
    },
  });
};

export const useGetEmployeeHistory = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["employee-history", params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("employee/getEmployeeHistory", {
        params: formatedSearchParams,
      });
      return data;
    },
    enabled: !!params?.employee_id,
  });
};

export const useGetActionTypesForEmployeeHistory = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["get-action-types-for-employee-history", params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/getActionTypesForEmployeeHistory",
        {
          params: formatedSearchParams,
        }
      );
      return data;
    },
  });
};

export const useGetLocationList = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["get-location-list", params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/getLocationList",
        {
          params: formatedSearchParams,
        }
      );
      return data;
    },
  });
};

export const useGetDepartmentList = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["get-department-list", params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/getDepartmentList",
        {
          params: formatedSearchParams,
        }
      );
      return data;
    },
  });
};

export const useGetDesignationList = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["get-designation-list", params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/getDesignationList",
        {
          params: formatedSearchParams,
        }
      );
      return data;
    },
  });
};

export const useGetLineManagerList = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["get-line-manager-list", params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/getLineManagerList",
        {
          params: formatedSearchParams,
        }
      );
      return data;
    },
  });
};

export const useGetHrList = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["get-hr-list", params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/getHRList",
        {
          params: formatedSearchParams,
        }
      );
      return data;
    },
  });
};

export const useCreateEmployee = (payload) => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: async () => {
      const { data } = axiosPrivate.post("employee/create", payload);
      return data;
    }
  })
}
